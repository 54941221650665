import { encode as base64_encode } from "base-64";
// import axios from "axios";
export const namazBaseURL = "http://api.aladhan.com/v1/";
// export const baseURL = "https://islamworldjazz.com/server/jazz-islam-world/public/api/";
export const baseURL = "https://islamworld.com.pk/server/jazz-islam-world/public/api/";

// Staging URL
// export const baseURL = "http://65.108.62.253/stagingjiw/api/";
// export const ImageBaseURL = "https://islamworldjazz.com ";
export const ImageBaseURL = "https://islamworld.com.pk";

///StagingURL////
// export const baseURL = 'http://65.108.62.253/stagingjiw/api/';
///StagingURL////

// export const ImageURL = "https://islamworldjazz.com";
export const ImageURL = "https://islamworld.com.pk";

let username = "asfandyar@econceptions.mobi";
let password = "6*C$q&3cd";

export const getNamazApi = async (query) => {
  let response = await fetch(namazBaseURL + query, {});
  return response;
};

export const GetApi = async (apiName) => {
  let url = baseURL;
  if (window.location.protocol === "http:") {
    url = url.replace("https", "http");
  }
  let response = await fetch(url + apiName, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + base64_encode(username + ":" + password),
    },
  });
  response = response.json();
  return response;
};

export const PostApi = async (body, apiName) => {
  let username = "asfandyar@econceptions.mobi";
  let password = "6*C$q&3cd";
  let headers = new Headers();
  headers.append(
    "Authorization",
    "Basic " + base64_encode(username + ":" + password)
  );
  let url = baseURL;
  if (window.location.protocol === "http:") {
    url = url.replace("https", "http");
  }
  let response = await fetch(url + apiName, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + base64_encode(username + ":" + password),
    },
    body: JSON.stringify(body),
  });
  response = response.json();
  return response;
};

export const fetchGetAuth = async (apiName, token) => {
  // console.log("token API", baseURL + apiName, token);
  let url = baseURL;
  if (window.location.protocol === "http:") {
    url = url.replace("https", "http");
  }
  let response = await fetch(`${url}${apiName}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  let jsonResponse = await response.json();
  return jsonResponse;
};

export const generateTokenPost = async (apiName) => {
  let url = baseURL;
  if (window.location.protocol === "http:") {
    url = url.replace("https", "http");
  }
  let response = await fetch(url + apiName, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer`,
    },
    body: JSON.stringify({
      username: "wAppJazzIWJWTUser",
      password: "iF587NIUk98X",
    }),
  });
  response = response.json();
  return response;
};

export const fetchGetAuthHttp = async (apiName, token) => {
  let url = baseURL;
  if (window.location.protocol === "http:") {
    url = url.replace("https", "http");
  }

  let response = await fetch(`${url}${apiName}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  let jsonResponse = await response.json();
  return jsonResponse;
};

export const GetApiHttp = async (apiName) => {
  let url = baseURL;
  if (window.location.protocol === "http:") {
    url = url.replace("https", "http");
  }
  let response = await fetch(url + apiName, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + base64_encode(username + ":" + password),
    },
  });
  response = response.json();
  return response;
};
